














import {
  defineComponent,
} from '@nuxtjs/composition-api';
import LoadWhenVisible from "~/components/utils/LoadWhenVisible.vue";
import {DelayHydration} from 'nuxt-delay-hydration/dist/components'

export default defineComponent({
  name: 'PageBuilderElement',
  props: {
    element: {
      type: Object,
      require: true
    }
  },
  components: {
    DelayHydration,
    LoadWhenVisible,
    Element_row: () => import("~/components/Palmers/PageBuilder/Element/Row.vue"),
    Element_column: () => import("~/components/Palmers/PageBuilder/Element/Column.vue"),
    Element_cta_full_width: () => import("~/components/Palmers/PageBuilder/Element/CtaFullWidth.vue"),
    Element_text: () => import("~/components/Palmers/PageBuilder/Element/Text.vue"),
    Element_static_block: () => import("~/components/Palmers/PageBuilder/Element/StaticBlock.vue"),
    Element_heading: () => import("~/components/Palmers/PageBuilder/Element/Heading.vue"),
    Element_empty_space: () => import("~/components/Palmers/PageBuilder/Element/EmptySpace.vue"),
    Element_button: () => import("~/components/Palmers/PageBuilder/Element/Button.vue"),
    Element_separator: () => import("~/components/Palmers/PageBuilder/Element/Separator.vue"),
    Element_tabs: () => import("~/components/Palmers/PageBuilder/Element/Tabs.vue"),
    Element_raw_html: () => import("~/components/Palmers/PageBuilder/Element/RawHtml.vue"),
    Element_single_image: () => import("~/components/Palmers/PageBuilder/Element/SingleImage.vue"),
    Element_search_form: () => import("~/components/Palmers/PageBuilder/Element/SearchForm.vue"),
    Element_single_product: () => import("~/components/Palmers/PageBuilder/Element/SingleProduct.vue"),
    Element_product_grid: () => import("~/components/Palmers/PageBuilder/Element/ProductGrid.vue"),
    Element_accordion: () => import("~/components/Palmers/PageBuilder/Element/Accordion.vue"),
    Element_toggle: () => import("~/components/Palmers/PageBuilder/Element/Toggle.vue"),
    Element_image_carousel: () => import("~/components/Palmers/PageBuilder/Element/ImageCarousel.vue"),
    Element_shop_the_look: () => import("~/components/Palmers/PageBuilder/Element/ShopTheLook.vue"),
    Element_promotion_banners: () => import("~/components/Palmers/PageBuilder/Element/PromotionBanners.vue"),
    Element_palmers_club: () => import("~/components/Palmers/PageBuilder/Element/PalmersClub.vue"),
    Element_five_images_with_text_banner: () => import("~/components/Palmers/PageBuilder/Element/FiveImagesWithTextBanner.vue"),
    Element_four_images_with_text_banner: () => import("~/components/Palmers/PageBuilder/Element/FiveImagesWithTextBanner.vue"),
    Element_text_overlap_banner: () => import("~/components/Palmers/PageBuilder/Element/TextOverlapBanner.vue"),
    Element_side_image_banner: () => import("~/components/Palmers/PageBuilder/Element/SideImageBanner.vue"),
    Element_favorite_categories: () => import("~/components/Palmers/PageBuilder/Element/FavoriteCategories.vue"),
    Element_category_grid: () => import("~/components/Palmers/PageBuilder/Element/CategoryGrid.vue"),
    Element_palmers_actions: () => import("~/components/Palmers/PageBuilder/Element/PalmersActions.vue"),
    Element_call_to_action: () => import("~/components/Palmers/PageBuilder/Element/CallToAction.vue"),
    Element_home_slider_video: () => import("~/components/Palmers/PageBuilder/Element/HomeSlider.vue"),
    Element_video_from_media: () => import("~/components/Palmers/PageBuilder/Element/VideoFromMedia.vue"),
    Element_menu_list: () => import("~/components/Palmers/PageBuilder/Element/MenuList.vue"),
    Element_list: () => import("~/components/Palmers/PageBuilder/Element/List.vue"),
    Element_images_list: () => import("~/components/Palmers/PageBuilder/Element/ImagesList.vue"),
    Element_palmers_club_mini: () => import("~/components/Palmers/PageBuilder/Element/PalmersClubMini.vue"),
    Element_newsletter: () => import("~/components/Palmers/PageBuilder/Element/Newsletter.vue"),
    Element_palmers_club_entry: () => import("~/components/Palmers/PageBuilder/Element/PalmersClubEntry.vue"),
    Element_palmers_club_cards: () => import("~/components/Palmers/PageBuilder/Element/PalmersCards.vue"),
    Element_palmers_club_register_steps: () => import("~/components/Palmers/PageBuilder/Element/PalmersClubRegister.vue"),
    Element_pagebuilder_template: () => import("~/components/Palmers/PageBuilder/Element/PageBuilderTemplate.vue"),
    Element_coin_shop_second_block: () => import("~/components/Palmers/PageBuilder/Element/CoinShopSecondBlock.vue"),
    Element_promo_banner: () => import("~/components/Palmers/PageBuilder/Element/PromoBanner.vue"),
    Element_jobs_list: () => import("~/components/Palmers/PageBuilder/Element/JobsList.vue"),
    Element_palmers_jobs_text_after: () => import("~/components/Palmers/PageBuilder/Element/PalmersJobTextAfter.vue"),
    Element_palmers_jobs_text_before: () => import("~/components/Palmers/PageBuilder/Element/PalmersJobTextBefore.vue"),
    Element_footer_bottom_with_payments: () => import("~/components/Palmers/PageBuilder/Element/FooterBottomWithPayments.vue"),
    Element_footer_block_with_socials: () => import("~/components/Palmers/PageBuilder/Element/FooterBlockWithSocials.vue"),
    Element_amasty_form: () => import("~/components/Palmers/PageBuilder/Element/AmastyForm.vue"),
    Element_product_slider: () => import("~/components/Palmers/PageBuilder/Element/ProductSlider.vue"),
    Element_end_of_sorry: () => import("~/components/Palmers/PageBuilder/Element/EndOfSorry.vue"),
    Element_ambassadors: () => import("~/components/Palmers/PageBuilder/Element/Ambassadors.vue"),
    Element_not_sorry_story: () => import("~/components/Palmers/PageBuilder/Element/NotSorryStory.vue"),
    Element_about_artist_slider: () => import("~/components/Palmers/PageBuilder/Element/AboutArtistSlider.vue"),
    Element_running_news: () => import("~/components/Palmers/PageBuilder/Element/RunningNews.vue"),
    Element_side_image_banner_new: () => import("~/components/Palmers/PageBuilder/Element/SideImageBannerNew.vue"),
    Element_instagram_reels: () => import("~/components/Palmers/PageBuilder/Element/InstagramReels.vue"),
    Element_instagram_feed: () => import("~/components/Palmers/PageBuilder/Element/InstagramFeed.vue"),
  },
  methods: {
    getClasses() {
      const classes = [];

      if (this.element.type === 'column') {
        if (this.element.sm_size) {
          classes.push(`mgz-col-sm-${this.element.sm_size}`)
        }

        if (this.element.sm_offset_size) {
          classes.push(`mgz-col-sm-offset-${this.element.sm_offset_size}`)
        }

        if (this.element.xl_size) {
          classes.push(`mgz-col-xl-${this.element.xl_size}`)
        }

        if (this.element.xl_offset_size) {
          classes.push(`mgz-col-xl-offset-${this.element.xl_offset_size}`)
        }

        if (this.element.md_size) {
          classes.push(`mgz-col-md-${this.element.md_size}`)
        }

        if (this.element.md_offset_size) {
          classes.push(`mgz-col-md-offset-${this.element.md_offset_size}`)
        }

        if (this.element.lg_size) {
          classes.push(`mgz-col-lg-${this.element.lg_size}`)
        }

        if (this.element.lg_offset_size) {
          classes.push(`mgz-col-lg-offset-${this.element.lg_offset_size}`)
        }

        if (this.element.xs_size) {
          classes.push(`mgz-col-xs-${this.element.xs_size}`)
        }

        if (classes.length <= 0) {
          classes.push('mgz-col-md-12');
        }
      }

      if (this.element.type === 'row') {
        classes.push(this.element.row_type)

        if (this.element.row_type == 'contained') {
          classes.push('mgz-container')
        }
      }

      if (this.element.animation_in) {
        classes.push('mgz-animated');
        classes.push('mgz_start_animation');
        classes.push('animated');
        classes.push(this.element.animation_in);
      }

      if (this.element.xl_hide) {
        classes.push(`mgz-hidden-xl`);
      }

      if (this.element.sm_hide) {
        classes.push(`mgz-hidden-sm`);
      }

      if (this.element.xs_hide) {
        classes.push(`mgz-hidden-xs`);
      }

      if (this.element.md_hide) {
        classes.push(`mgz-hidden-md`);
      }

      if (this.element.lg_hide) {
        classes.push(`mgz-hidden-lg`);
      }

      if (this.element.el_class) {
        classes.push(this.element.el_class);
      }

      return classes;
    },
    getInnerClasses() {
      const classes = [`${this.element.id}-s`];

      if (this.element.el_inner_class) {
        classes.push(this.element.el_inner_class)
      }

      return classes;
    },
    getInnerStyles() {
      const styles = {};

      if (this.element.content_align === 'center') {
        styles['margin'] = '0 auto';
      }

      return styles;
    },
    getStyles() {
      const styles = {};

      if (this.element.z_index) {
        styles['z-index'] = this.element.z_index;
      }

      return styles;
    }
  },
  mounted() {
    // console.log(this.element)
  }
});

//@TODO GENERAL
//@Todo hide on page load
//@Todo Custom css/classes+inner/ids/z-index
//@Todo Background images/videos per device

//@TODO PER ELEMENTS
//@Todo Tabs add styles background/hover/active/add icons. Hide if empty/ mobile accordion. Do not fill content area/ active on hover
